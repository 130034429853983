@use '@/styles/global' as g;
@use '@/styles/global/zindex' as zIndexes;

.channelGuidePaddings {
    padding: 0;

    @include g.bp(md) {
        padding: 0px 24px;
    }

    @include g.bp(lg) {
        padding: 0px 40px;
    }

    @include g.bp(xl) {
        padding: 0px 80px;
    }
}

.headerAreaWrapper {
    display: flex;
    position: sticky;
    top: 80px;
    padding-top: 24px;
    padding-bottom: 16px;
    flex-direction: column;
    z-index: 3;
    background-color: var(--white);

    &.openYmmStyles {
        z-index: zIndexes.$modal - 20;
    }

    @include g.bp(md) {
        top: 83px;
    }

    &.legacyTopNavStyles {
        top: 0px;
    }
}
.channelGuideContentWrapper {
    min-height: 80vh;
    padding: 0px 16px;

    @include g.bp(md) {
        padding: 0px;
    }
}
.channelGuideSkeletonWrapper {
    margin-bottom: 12px;
}
.tabsSelectorWrapper {
    display: block;

    @include g.bp(md) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.selectorsWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 24px;
    padding: 0px 16px;

    @include g.bp(md) {
        display: flex;
        gap: 8px;
        padding: 0px;
    }
}

.channelList {
    z-index: 1;

    .explicitContainerWrapper {
        display: flex;
        align-items: flex-start;
        margin-top: 24px;

        .xlDescription {
            color: var(--base-contrast-subtle);
            flex: 1;
        }
        .badgeStyles {
            margin-right: 4px;
            flex: 1;
            max-width: 24px;
            margin-top: 1px;
        }
    }
}
.channelCardStripWrapper {
    margin-bottom: 12px;
}

.ymmTriggerWrapper {
    display: flex;
    align-items: center;
    padding: 0px 16px;

    @include g.bp(md) {
        padding: 0px;
    }

    svg {
        display: none;
        @include g.bp(md) {
            display: inline-block;
        }
    }

    .ymm {
        font-size: inherit;
        padding: 0;
        line-height: 24px;
        margin-left: 4px;

        svg {
            display: inline-block;
        }

        button,
        b {
            font-weight: inherit;
            color: var(--base-contrast);
        }
        button {
            margin-left: 6px;
        }
    }
}
.selectorStyles {
    margin-bottom: 0px !important;
    width: 100%;
    min-width: 160px;

    @include g.bp(md) {
        max-width: 332px;
    }

    span,
    .selectOption {
        @include g.set-font-weight('medium');
    }
}
.downloadLinkDesktop {
    display: none;

    @include g.bp(md) {
        text-align: right;
        display: initial;

        .linkColor {
            text-decoration: none;
            color: #191919;
            @include g.set-font-weight('medium');
            @include g.font-size(14px);
            line-height: 1.2;
        }
    }
}
.downloadLinkMobile {
    text-align: center;
    margin-top: 40px;
    padding: 0px 16px;

    .linkColor {
        text-decoration: none;
        color: #191919;
        @include g.set-font-weight('medium');
        @include g.font-size(14px);
        line-height: 1.2;
    }

    @include g.bp(md) {
        display: none;
    }
}
.downloadTextWrapper {
    display: flex;
    align-items: center;

    svg {
        margin-right: 6px;
    }
}
.showMore {
    width: 100%;
    color: var(--base-contrast-subtle) !important;

    svg {
        margin-left: 24px;
    }
}
.showMoreTextStyles {
    color: var(--base-contrast-subtle);
    @include g.font-size(14px);
    @include g.set-font-weight('medium');
}
.showMoreButton {
    width: 100% !important;
    padding: 8px !important;
    max-width: none;
}
.showMoreAreaStyles {
    justify-content: space-between;
    padding: 0 12px;
    svg {
        margin-left: 24px;
    }
    path {
        stroke: var(--base-contrast-subtle) !important;
    }
}

.showMoreText {
    flex-grow: 1;
}

.show {
    display: initial;
}

.hidden {
    &:not(.show) {
        display: none;
    }
}
.selectPlanHeaderSubTitleStyles {
    @include g.font-size(14px);
    color: var(--base-contrast-subtle);

    svg {
        width: 20px;
        height: 20px;
        vertical-align: middle;

        path {
            stroke: var(--base-contrast-subtle);
        }
    }
}

.ymmButton {
    display: flex;
    align-items: center;
}

.ymmTextBar {
    color: var(--base-contrast, #202020);
    text-decoration: underline;
    @include g.set-font-weight('book');
}

.ymmPreTextBar {
    color: var(--neutral-contrast-subtle, #646464);
    @include g.set-font-weight('book');
    display: flex;
    align-items: center;
    padding-right: 6px;
}

.channelIcon {
    margin-right: 4px;
    width: 20px;
}

.iconStyle {
    margin-left: 8px;
    width: 12px;
    stroke: var(--base-contrast);
    stroke-width: 2px;
}
