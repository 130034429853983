@use '@/styles/global' as g;

.modal {
    .lpHeading {
        @include g.font-size(28px);
        line-height: 32px;
        @include g.set-font-weight('black');
        margin-bottom: 0px;
    }

    .vehicleInfoWrapper {
        padding: 16px 0px;
        margin-bottom: 16px;
        border-bottom: 1px solid g.$black;

        p {
            margin: 0px;
        }

        p:first-of-type {
            @include g.set-font-weight('bold');
        }
    }

    .ymmForm {
        margin-top: 32px;
        padding-bottom: 24px;

        .invalidFeedback {
            margin-top: 8px;
        }
    }

    .buttons {
        margin-top: 8px;
    }

    .textButton {
        @include g.font-size(14px);
        text-decoration: underline;
        text-decoration-thickness: 2px;
        @include g.set-font-weight('book');
        color: g.$blue-secondary;
        margin-top: 8px;
    }

    .noSupport {
        width: 100%;
        margin-top: 16px;
        @include g.font-size(14px);
        color: g.$blue;
    }
}