@use '@/styles/global' as g;

.upcomingSportsTitle {
    margin-bottom: 40px;
    @include g.font-size(30px);
    line-height: 1.04;
    @include g.set-font-weight('black');

    @include g.bp(md) {
        @include g.font-size(38px);
    }

    @include g.bp(lg) {
        @include g.font-size(48px);
    }
}

.cardsContainer {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;

    @include g.bp(lg) {
        overflow-x: visible;
    }

    .sportsCard {
        padding: 8px 4px 24px;

        .eyebrow {
            margin-bottom: 12px;
        }
    }
}

.sportsCard span.noTransform {
    text-transform: none;
}
