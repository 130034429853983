@use '@/styles/global' as g;

.noSlideWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 16px;
    padding: 0px;

    @include g.bp(md) {
        padding: 0px 24px;
    }

    @include g.bp(lg) {
        padding: 0px;
        grid-template-columns: var(--grid-fraction-layout);
    }

    .tileWrapper,
    .promoItemStyle {
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;

        @include g.bp(md) {
            min-width: none;
        }

        @include g.bp(lg) {
            aspect-ratio: auto;
            height: 100%;
        }
    }
}

.tileWrapper {
    width: 312px;
    height: 312px;

    @include g.bp(md) {
        width: 352px;
        height: 352px;
    }

    @include g.bp(lg) {
        width: 100%;
        height: 100%;
        max-width: 416px;
        max-height: 416px;
    }
}

.promoItemStyle {
    flex: 1;
}

.tileStyles,
.promoItemSlideStyle {
    width: calc(50vw - 24px);
    min-width: 264px;
    height: auto;
    aspect-ratio: 1 / 1;

    @media (min-width: 390px) {
        min-width: 312px;
    }
}

.promoItemSlideStyle {
    @media (max-width: 389px) {
        h4 {
            @include g.font-size(18px);
        }
    }
}
