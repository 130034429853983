@use './mixins' as m;
@use './variables' as v;
@use './functions' as f;

@include m.font-print(v.$header-styles);

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    display: block;
    @include m.font-weight('black');
    line-height: 1;
}

.p,
.paragraph {
    display: block;
    margin-top: 0;
    margin-bottom: 12px;
    line-height: 1.5;
    @include m.font-size(16px);

    &.text-xlarge {
        margin-bottom: 14px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.text-bold {
    @include m.font-weight('bold');
}

.text-detail {
    line-height: 1.2;
    margin-bottom: 0;
    @include m.font-size(16px);
}

.text-badge {
    line-height: 1;
    text-transform: uppercase;
    @include m.font-size(14px);
}

.text-label {
    line-height: 1.25;
    margin-bottom: 0;
    @include m.font-weight('medium');
    @include m.font-size(16px);
}

.text-legal,
.text-legal p {
    @include m.font-size(12px);
    line-height: 1.5;

    [href^='tel:'] {
        color: inherit;
        text-decoration: none;

        &::before,
        &::after {
            content: none;
        }
    }

    a {
        color: inherit;
    }
}

// Text t-shirt size modifiers. To be used with p/.paragraph, .text-detail, .text-badge, etc
.text-xlarge {
    @include m.font-size(18px);
}

.text-large {
    @include m.font-size(16px);
}

.text-medium {
    @include m.font-size(14px);
}

.text-small {
    @include m.font-size(12px);
}

// Modifiers
// =================================
.text-nowrap {
    white-space: nowrap;
}

.text-underline {
    text-decoration: underline;
}

.text-black-weight {
    @include m.font-weight('black');
}

// Basic Typography text align options
.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

// text transform
// =================================
.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}
