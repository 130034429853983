@use '@/styles/global' as g;

.pricing_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: left;
    background-color: g.$blue-ultra-light;
    margin-top: 1rem;
    padding-left: 0;
    padding-right: 0;

    @include g.bp(md) {
        text-align: center;
        gap: 0;
        justify-content: center;
        align-self: stretch;
        padding-left: 40px;
        padding-right: 40px;
        margin-top: 2rem;
    }
}

.pricing_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-self: stretch;
    padding: 1rem 1rem 0;

    @include g.bp(md) {
        padding: 2rem 1rem 1rem;
    }
}

.title {
    @include g.font-size(27px);
    @include g.set-font-weight('black');
    line-height: 32px;
    color: g.$black;

    @include g.bp(md) {
        @include g.font-size(32px);
        line-height: 36px;
    }
}

.description {
    @include g.font-size(16px);
    line-height: 20px;
    color: g.$almost-black;

    @include g.bp(md) {
        @include g.font-size(18px);
        line-height: 24px;
    }
}

.sub_wrapper {
    display: flex;
    flex-direction: column;

    @include g.bp(md) {
        flex-direction: row;
        align-self: stretch;
    }

    @include g.bp(xl) {
        height: 250px;
    }
}

.pricing_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 1rem;
    background-color: g.$blue-ultra-light;
    align-self: stretch;

    @include g.bp(md) {
        justify-content: center;
    }
}

.appLinkHref {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    text-decoration: none;
}

.benefit_icon {
    width: 16px;
    height: 16px;
    background-size: cover;
    min-width: 16px;
}

.benefit_icon circle {
    fill: g.$blue;
    stroke: g.$blue;
}

.benefit_icon path {
    stroke: g.$white;
}

.benefit {
    @include g.font-size(14px);
    line-height: 16px;
    color: g.$white;
    text-align: left;
}

.crowd_desktop_container {
    display: none;
    margin-left: auto;
    width: 100%;
    height: 100%;
    min-height: 230px;
    max-height: 100%;
    align-self: stretch;
    flex-grow: 1;
    object-fit: cover;

    @include g.bp(md) {
        display: flex;
    }
}

.stella_desktop_container {
    display: none;

    @include g.bp(md) {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        padding-right: 1rem;
        justify-content: flex-end;
    }
}

.end_column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.stella_desktop {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-size: cover;
}

.details {
    display: flex;
    flex-direction: column;
    background-color: g.$blue;
    padding-left: 1rem;
    height: 180px;
    justify-content: space-around;

    @include g.bp(md) {
        padding-right: 32px;
        justify-content: center;
        height: 100%;
        gap: 16px;
    }

    @include g.bp(lg) {
        padding-left: 32px;
    }
}

.title_box {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    text-align: left;
    padding-top: 12px;
}

.title_first_line {
    --tw-text-opacity: 1;
    color: rgb(245 245 245 / var(--tw-text-opacity));
    line-height: 1;
    @include g.font-size(14px);

    @include g.bp(sm) {
        @include g.font-size(16px);
        line-height: 1.5rem;
    }
}

.title_second_line {
    line-height: 1.5rem;
    color: g.$white;
    @include g.font-size(24px);
    @include g.set-font-weight('black');

    @include g.bp(sm) {
        @include g.font-size(20px);
    }
}

.subtitle_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 12px;
    gap: 0.5rem;

    @include g.bp(md) {
        height: auto;
    }
}

.pricing_box {
    display: flex;
    flex-direction: column;
    padding-right: 1rem;
    width: 260px;
    text-align: left;
    flex-grow: 1;
    gap: 8px;

    @include g.bp(md) {
        height: auto;
        min-width: 240px;
        max-width: 446px;
        width: calc(50vw - 300px);
    }
}

.offer {
    @include g.font-size(34px);
    @include g.set-font-weight('black');
    line-height: 30px;
    color: g.$white;

    @include g.bp(md) {
        @include g.font-size(44px);
        line-height: 40px;
    }

    @media (min-width: 1280px) {
        @include g.font-size(48px);
        line-height: 3rem;
    }
}

.extended_terms {
    --tw-text-opacity: 1;
    color: rgb(245 245 245 / var(--tw-text-opacity));
    @include g.font-size(12px);
    line-height: 0.75rem;
}

.best_plan_box {
    display: flex;
    flex-direction: column;
    background-color: g.$black;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    white-space: nowrap;
    display: none;

    @include g.bp(md) {
        margin-top: 1.35rem;
        padding: 0.8125rem 1rem 0.8125rem 1rem;
        display: none;
    }
}

.arrow_image {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 14px;
    align-self: stretch;
    margin: auto 0;
    margin-left: 0.5rem;
    margin-top: 4px;
}

.best_plan_text {
    font-weight: 700;
    font-size: 12px;
    @include g.font-size(12px);
    @include g.set-font-weight('bold');
    text-align: center;
    color: g.$white;

    @include g.bp(md) {
        @include g.font-size(20px);
        @include g.set-font-weight('black');
    }
}

.stella_mobile_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: stretch;
    padding-left: 1rem;

    @include g.bp(md) {
        display: none;
    }
}

.stella_mobile {
    background-size: cover;
    width: 86px;
    height: 61px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.action {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: g.$blue;
    margin: 0 auto;
    padding: 0.75rem 1rem 0.75rem 1rem;
    margin-top: 0.5rem;
    margin-bottom: 2rem;

    @include g.bp(md) {
        margin-top: 1rem;
    }
}

.action_text {
    @include g.font-size(16px);
    @include g.set-font-weight('bold');
    line-height: 1.5rem;
    color: g.$white;
}

.benefits_main_box {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 0.625rem;
    padding-left: 4rem;
    --tw-bg-opacity: 1;
    background-color: rgb(245, 245, 245, var(--tw-bg-opacity));

    @include g.bp(md) {
        align-items: center;
        align-self: stretch;
        gap: 0px;
        padding-left: 0px;
    }
}

.crowd_mobile_container {
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow: hidden;
    height: 231px;
    align-self: flex-start;
    z-index: 1;
    bottom: 0px;
    right: 0px;
    margin-left: 40px;
    width: calc(100% - 40px);

    @include g.bp(md) {
        display: none;
    }
}

.crowd_mobile {
    object-fit: contain;
    width: 100%;
    aspect-ratio: 1.15;
}

.benefits_main_container {
    display: flex;
    position: relative;
    flex-direction: column;
    z-index: 10;
    margin: auto 0 auto auto;
    height: 230px;
    width: 80%;
    background-color: rgba(0, 0, 0, 0.8);

    @include g.bp(md) {
        align-self: stretch;
        flex-grow: 1;
        height: 100%;
        width: 100%;
        max-width: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.benefits_container {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    width: 100%;
    @include g.font-size(14px);
    line-height: 1rem;
    gap: 0.75rem;
    color: g.$white;
    justify-content: center;

    @include g.bp(md) {
        align-self: stretch;
        flex-grow: 1;
        gap: min(1.25vw, 16px);
    }

    @include g.bp-max(sm) {
        justify-content: flex-start;
        margin-top: 32px;
    }
}

.benefit_container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;
    padding-left: 16px;

    @include g.bp(md) {
        align-self: stretch;
    }
    @include g.bp(xl) {
        padding-left: 32px;
    }
}

.footnote {
    color: g.$white;
    margin-left: 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @include g.font-size(10px);
    line-height: 0.75rem;
    position: absolute;
    bottom: 12px;
    right: 12px;
    text-align: left;

    & > :first-child {
        @include g.font-size(18px);
        padding-top: 3px;
    }
    
    @include g.bp(lg) {
        @include g.font-size(12px);
    }
}

.flex_center_stretch {
    display: flex;
    align-items: center;
    align-self: stretch;
}

.bold_text {
    @include g.set-font-weight('bold');
}
