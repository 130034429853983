@use '@/styles/global' as g;

.footerContainer,
.simpleFooter {
    position: relative;

    @include g.bp(md) {
        padding-top: 40px;

        &::before {
            content: '';
            height: 1px;
            background-color: g.$gray-light !important;
            position: absolute;
            top: 0;
            width: 100%;
            left: 0;
        }
    }
}

.simpleFooter {
    @include g.bp(md) {
        padding-top: 24px;
    }
}

.basicFooter {
    padding-top: 36px;
}