.playerWrapper {
    margin: 0 auto;
}

.elementWrapper {
    margin-bottom: 3rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-transition: height 1s ease;
    transition: height 1s ease;
}
  
@media only screen and (max-width: 68.125em) {
.elementWrapper {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
}

@media only screen and (max-width: 45em) {
.elementWrapper {
    width: 100%;
}
}

.playerWrapper {
width: 100%;
}

.playerWrapper > * {
min-width: 100%;
}