@use '@/styles/global' as g;

$SiriusXM-Aldebaran-Orange: rgba(255, 97, 41, 1);
$SiriusXM-Rigel-Cyan: rgba(49, 200, 255, 1);

.container {
    display: flex;
    padding: 32px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    position: relative;
}  

.uppershape {
    position: absolute;
    width: 33.3%;
    height: 154px;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: $SiriusXM-Aldebaran-Orange;
}

.lowershape {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -2;
    width: 50%;
    height: 102.667px;
    background-color: $SiriusXM-Rigel-Cyan;
}

.cta {
    display: flex;
    width: min(100%, max(47.5%, 657px));
    align-items: center;
    align-self: center;
    padding: 32px;
    gap: 32px;
    background-color: g.$blue;
    margin: 0 auto;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    color: g.$white;
}

.h5 {
    @include g.font-size(20px);
    @include g.set-font-weight('black');
    line-height: 24px;
}

.h4 {
    @include g.font-size(24px);
    @include g.set-font-weight('book');
    line-height: 1.5rem;
}

.h2 {
    @include g.font-size(48px);
    @include g.set-font-weight('black');
    line-height: 48px;
}

.p {
    @include g.font-size(14px);
    @include g.set-font-weight('book');
    line-height: 22px;
    .b {
        @include g.set-font-weight('bold');
    }
}



.link {
    text-decoration: none;
}  

.play {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    width: 57px;
    height: 64px;
    .arrow {
        max-width: 100%;
        width: auto;
        height: auto;
        display: block;

        .arrow {
            height: 64px;
            width: 57px;
        }
    }
}

.conversion {
    font-size: medium;
}

.hidden {
    padding: 0;
    .uppershape,
    .lowershape {
        display: none;
    }

    .link {
        width: 100%;
    }

    .cta {
        width: 100%;
        padding: min(2.75vw, 30px);
    }

    .info {
        padding: 0px min(2vw, 24px) 0px min(5.75vw, 108px);
        gap: 4px;
    }

    .info.general {
        @include g.bp(md) {
            gap: min(2.5vw, 40px);
            padding: 0px min(2vw, 24px) 0px min(5.75vw, 108px);
        }
    }   

    .info.conversion {
        padding: 12px min(2vw, 24px);
        margin-right: unset;
        gap: 8px;

        @include g.bp(md) {
            padding: 0;
            gap: 6px;
        }
    }
}

@include g.bp-max(md) {
    .container {
        padding: 16px;
        justify-content: space-between;
    }

    .uppershape {
        height: 98px;
    }

    .lowershape {
        height: 66px;
    }

    .cta {
        width: 100%;
        gap: 0;
        padding: 0;
    }

    .info {
        padding: 16px;
        align-self: stretch;
    }

    .h5 {
        @include g.font-size(14px);
        @include g.set-font-weight('bold');
        line-height: 0.875rem;
    }
    .h4 {
        @include g.font-size(16px);
        line-height: 1.2rem;
    }
    .h2 {
        @include g.font-size(34px);
        @include g.set-font-weight('black');
        line-height: 30px;
    }
    .p {
        @include g.font-size(12px);
        line-height: 14px;
        .b {
            @include g.font-size(48px);
            @include g.set-font-weight('bold');
        }
    }

    .play {
        padding: 0px 16px;
        gap: 10px;
        .arrow {
            .arrow {
                width: 24px;
                height: 24px;
            }
        }
    }

    .hidden {
        .uppershape,
        .lowershape {
            display: inline;
        }
        .cta {
            padding: 0;
        }
        .info {
            padding: 12px 16px;
            gap: 8px;
            align-self: stretch;
        }
        .play {
            width: unset;
            height: unset;
        }
    }
}

.showOnConversionMobileOnly {
    display: flex;

    @include g.bp(md) {
        display: none;
    }
}

.showOnConversionDesktopOnly {
    display: none;

    .uppershape, .lowershape {
        display: flex;
    }

    @include g.bp(md) {
        display: flex;
        padding: 16px;
    }
}

.showOnConversionMobileAndDesktop{
    display: flex;
}

.hideOnConversionMobileAndDesktop{
    display: none;
}