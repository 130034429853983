@use '@/styles/global' as g;

.grid_container {
    background: g.$black;
    background-color: g.$black;
}

.title_pane {
    width: 100%;
    margin-top: 48px;
    padding: 1rem 0 1rem 0;
    background-color: g.$black;
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;

    @include g.bp(sm) {
        gap: 1.5rem;
        padding: 4rem 0px 24px 0p;
    }

    .content {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.5rem;

        .topic {
            padding: 0 0rem;
            background-color: g.$white;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            .text {
                color: g.$black;
                font-size: 1rem;
                font-weight: 500;
                line-height: 1rem;
            }
        }

        .title {
            align-self: stretch;
            color: g.$white;
            font-size: 34px;
            line-height: 40px;
            font-weight: 800;

            @include g.bp(sm) {
                font-size: 48px;
                line-height: 48px;
            }
        }

        .title span {
            display: inline;
        }

        .subtitle p {
            align-self: stretch;
            color: g.$white;
            font-size: 1rem;
            font-weight: 300;
            line-height: 1rem;

            @include g.bp(sm) {
                font-size: 20px;
                line-height: 24px;
            }
        }
    }
}
