@use '@/styles/global' as g;

.wrapper {
    text-align: left; // Stops inherited values from misaligning modal text
}

.p1 {
    margin-top: 32px;
}

.onlineDesc {
    @include g.font-size(12px);
    padding-left: 30px;
}

.lpHeading {
    margin-bottom: 0;
}