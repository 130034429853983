@use '@/styles/global' as g;

.packageLabel {
    @include g.font-size(14px);
    background-color: g.$raspberry;
    color: g.$white;
    display: block;
    @include g.set-font-weight('bold');
    padding: 8px 32px;
    text-transform: uppercase;
    width: 100%;

    @include g.web2PageOverrides {
        background-color: var(--base-contrast);
    }
}