@use '@/styles/global' as g;

.channelSelectorWrapper {
    display: flex;
}
.buttonStyles {
    min-width: 80px;
    height: 44px;
    padding: 0px 16px;

    @include g.bp(md) {
        margin-right: 8px;
    }


    span {
        color: var(--base-button-primary-contrast)!important;
    }
}