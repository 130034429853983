@use '@/styles/global' as g;

.sticky.stickyEnd {
    position: absolute !important;
    bottom: var(--sticky-end-pixels);
    top: unset;
}

.sticky {
    @include g.set-font-weight('black');
    margin-bottom: 24px;

    @include g.bp(md) {
        top: 87px;
        position: fixed;
        margin-bottom: unset;
        width: 36%;
    }
}

.stickyHeader {
    b {
        @include g.set-font-weight('black');
    }
}

.scrollPlaceHolder {
    height: 1px;
}

.header {
    position: relative;
    padding: 57px 0 72px;
}

.listSection {
    background: g.$blue;
    color: g.$white;
    padding: 57px 0 96px;

    li {
        @include g.font-size(30px);
        @include g.set-font-weight('black');
        padding-left: 30px !important;
        padding-bottom: 56px;

        b {
            @include g.font-size(30px);
            @include g.set-font-weight('black');
        }

        @include g.bp(md) {
            @include g.font-size(45px);

            b {
                @include g.font-size(45px);
            }
        }
    }

    li:last-of-type {
        padding-bottom: 0px;
    }
}

.colorChange {
    color: g.$white;
}