@use '@/styles/global' as g;

.box {
    min-height: 25px;
    position: fixed;
    left: 0;
    right: 0;
    display: none;
    z-index: 10;
}

.top {
    top: var(--offset);

    @include g.bp(md) {
        top: 0;
    }
}

.bottom {
    bottom: 0;
}

.topDesktopBottomMobile {
    bottom: 0;

    @include g.bp(md) {
        top: 0;
        bottom: unset;
    }
}

.show {
    display: block;
}