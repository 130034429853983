@use '@/styles/global' as g;

.title {
    margin-bottom: 0px;
}
.selectStyles {
    width: 100%;
}
.selectInputWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;

    @include g.bp(md) {
        flex-direction: row;
    }
}
.buttonStyles {
    margin-bottom: 32px;
}
.linksWrapper {
    display: flex;
    flex-direction: column;
}
.linkStyles {
    text-decoration-thickness: 1px;
    margin-bottom: 24px;
    width: max-content;
}