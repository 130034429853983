@use '@/styles/global' as g;

.mainWrapperStyles {
    display: none;

    @include g.bp(lg) {
        display: flex;
        flex-direction: column;
        max-width: 360px;
    }
}

.hideOnMobile {
    display: none;
}

.imagesWrapperStyles {
    display: flex;
    gap: 10px;
    margin-bottom: 8px;

    .staticImageStyles,
    .qrCodeStyles {
        width: 96px;
        height: 96px;
    }
}

.ctaButtonStyles {
    display: block;
    width: 100%;
    margin: 0px;

    @include g.bp(lg) {
        display: none;
    }
}

.displayButton {
    display: block;
    width: 100%;
    margin: 0px;
}

.bodyTextStyles {
    @include g.font-size(12px);
    line-height: 1.6;
    margin: 16px 0px;
}