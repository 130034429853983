@use '@/styles/global' as g;

@keyframes fadeIn {
    from {
        display: block;
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.trialEligibilitySticky {
    &.desktop {
        width: 100%;
        background-color: g.$secondary-atlas-web-blue;
        display: none;
        opacity: 0;
        transition: all 0.4s ease-out;
        color: g.$white;

        @include g.bp(lg) {
            @include g.set-font-weight('medium');
            background: g.$white-trans-dark;
            backdrop-filter: blur(10px);
            color: g.$black;
        }

        .logo {
            display: none;

            @include g.bp(lg) {
                display: block;
            }
        }
    }

    .container {
        display: flex;
        justify-content: space-between;
        column-gap: 4px;
        align-items: center;
        flex-direction: row;
        height: 64px;

        @include g.bp(md) {
            column-gap: 16px;
        }

        svg {
            fill: g.$black;
            width: 136px;
        }

        p {
            @include g.font-size(12px);
            margin: 0;
        }

        .ctaButton button {
            margin: 0;
            @include g.font-size(12px);
            width: 114px;
            min-width: 114px;
            height: 32px;
            text-transform: none;
            background: g.$white;
            min-height: 32px;
            padding: 4px 8px;

            &:hover,
            &:focus {
                background: g.$secondary-atlas-web-blue;
                color: g.$white;

                &::before {
                    border-color: g.$white;
                }
            }

            @include g.bp(lg) {
                background: g.$secondary-atlas-web-blue;
                color: g.$white;
                min-width: 159px;
                height: 44px;
                min-height: 44px;

                &:hover,
                &:focus {
                    background: g.$white;
                    color: g.$secondary-atlas-web-blue;

                    &::before {
                        border-color: g.$secondary-atlas-web-blue;
                    }
                }
            }
        }
    }

    .textButton {
        font-size: inherit;
        font-weight: inherit;
        @include g.link-underline-properties;
    }

    .newLineText {
        display: block;
        @include g.font-size(11px);
        @include g.set-font-weight('book');
        line-height: 1.18;
        letter-spacing: -0.22px;

        @include g.bp(lg) {
            display: inline;
            @include g.font-size(12px);
            @include g.set-font-weight('medium');
            letter-spacing: 0;
        }
    }

    .mobileText {
        @include g.font-size(16px);
        @include g.set-font-weight('bold');
        line-height: 1.25;
        letter-spacing: -0.16px;

        @include g.bp(lg) {
            display: none;
        }
    }

    .desktopText {
        display: none;

        @include g.bp(lg) {
            display: inline;
        }
    }

    &.showBox {
        animation: fadeIn forwards 0.4s ease-in;
        display: block;
    }
}