@use '@/styles/global' as g;

.headline {
    margin-bottom: 16px;

    a {
        display: none;

        @include g.bp(md) {
            display: block;
        }
    }
}

.group {
    display: flex;
    flex-direction: column;

    @include g.bp(lg) {
        flex-direction: row;
        row-gap: 15px;
        flex-wrap: wrap;
    }

    .cardWrapper {
        display: flex;
        position: relative;
        z-index: 1;
        margin: 4px 0 0;

        @include g.bp(lg) {
            width: 24%;
            margin: 8px 0.5%;
        }

        .lastCard {
            margin-bottom: 16px;

            @include g.bp(md) {
                margin-bottom: 0;
            }
        }
    }

    .cardWrapper:hover {
        z-index: 2;
    }
}

.mobileLink {
    display: inline-block;
    margin-top: map-get(g.$page-layout-spacing, micro);
    text-transform: capitalize;

    @include g.bp(md) {
        display: none;
    }
}