@use '@/styles/global' as g;

.agreementContainer {
    @include g.font-size(16px);

    div {
        margin: 16px 0 12px 0;
    }

    a {
        color: g.$blue-secondary;
    }
}

.success {
    padding: 0px 13px 25px 13px;

    @include g.bp(md) {
        padding-top: 18px;
    }

    h2 {
        margin: 16px 0 12px 0;
    }

    svg {
        width: 43px;
        height: 43px;

        path,
        circle {
            stroke: g.$green;
        }
    }
}

.select {
    margin-bottom: 16px !important;
}