.callCenter {
    padding: 24px 0;
    height: 70px;
    overflow: hidden;
    transition:
        padding 0.3s,
        height 0.3s;
}

.callCenter:not(.show) {
    height: 0;
    padding: 0;
}

.imageWithLink {
    width: auto;
    height: auto;
    display: inline-block;
}

.loadingChat {
    position: relative;
    max-width: 296px;

    img {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 1;
        width: 75px;
    }
}