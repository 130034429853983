@use '@/styles/global' as g;

.mlpSubheadlineH3 {
    margin-bottom: 0 !important;
}

.formContainer {
    background-color: g.$white;
    padding: 24px;
    display: flex;
    justify-content: center;

    @include g.bp(md) {
        margin: 24px 0 24px auto;
        max-width: 496px;
    }

    & > * {
        width: 100%;
        color: g.$black;
    }
}

.formContainer,
.nflForm {
    flex-direction: column;
}

.ctaButton {
    &:hover,
    &:focus,
    &:active {
        color: var(--button-secondary-interaction-contrast, g.$white) !important;
    }
}

.socialIconsWhite svg {
    fill: g.$white;
}

.socialIconsBlack svg {
    fill: g.$black;
}

.heroLinks {
    margin: 0 16px 0 16px;

    @include g.bp(md) {
        width: 100%;
        max-width: 390px;
        position: absolute;
        right: 0;
        bottom: -48px;
    }
}

.overridesIfLightWeb2 {
    @include g.web2PageOverrides {
        --web2BreadcrumbOverride: var(--base-contrast-subtle, #646464);
    }
}
