@use '@/styles/global' as g;

.dateTitle {
    @include g.set-font-weight('bold');
    text-transform: uppercase;
    margin-top: 24px;
    margin-bottom: 12px;
    letter-spacing: 1px;
}

.allGamesMessage {
    margin-top: 24px;
    color: g.$gray-dark;
}

.listStylings {
    padding: 0px;
    margin: 0px;

    li {
        list-style: none;
    }
}

.filterBar {
    position: relative;
    z-index: 3;
    background-color: g.$purple;
    display: flex;
    flex-direction: row;

    @include g.web2PageOverrides {
        gap: 16px;
        background-color: transparent;
        flex-direction: column;

        @include g.bp(md) {
            flex-direction: row;
        }

    }
}

.noGames {
    text-align: center;

    img.large {
        width: 72px;
        height: 72px;
        margin: 0 auto;
        opacity: 0.5;
    }

    img.small {
        width: 20px;
        height: 20px;
        margin: 0 auto;
    }

    .noGamesTitle {
        margin-top: 21px;
        margin-bottom: 8px;
    }

    .noGamesMsg {
        color: g.$gray-dark;
        max-width: 490px;
        margin: 0 auto;
    }

    .noGamesAnchor {
        @include g.set-font-weight('bold');
        letter-spacing: 2px;
        color: g.$blue;
    }
}

.noGamesWrapper {
    .noGamesTitle {
        margin-top: 21px;
        margin-bottom: 8px;
        color: g.$black;
        @include g.set-font-weight('black');
    }

    .noGamesMsg {
        color: g.$secondary-deep-gray;
        max-width: none;
        margin: 0 auto;
    }

    .ymmTrigger {
        button {
            letter-spacing: 2px;
            color: g.$secondary-atlas-web-blue;
            display: inline;
            line-height: 22px;
            @include g.set-font-weight('book');
            text-decoration-thickness: 1px;
            letter-spacing: normal;
        }

        .textStyling {
            display: inline;
        }
    }
}

.moreGames {
    width: 288px !important;
    white-space: wrap;
    line-height: normal !important;
    height: auto;
    padding: 16px !important;
    max-height: none;
    border-top: 1px solid g.$white;
}

.loadMoreGames {
    padding: 16px;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 60px;
    text-align: center;
    background-color: g.$solitude-blue;
}

.loadMoreMessage {
    @include g.font-size(14px);
    color: g.$blue-secondary;
    cursor: pointer;
    display: block;
    margin-bottom: 8px;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px;
}