@use '@/styles/global' as g;
@use '@/styles/global/zindex' as zIndexes;

.section {
    position: relative;
    min-height: 100vh;
    z-index: zIndexes.$full-nav-base - 5;

    &.openYmm {
        // We dynamically have to change the zIndex to not to collide with the navHeader when the YMM Modal is open:
        z-index: zIndexes.$full-nav-base + 5;
    }

    .stickyBar {
        position: sticky;
        top: 0;
        z-index: 7;
        background-color: g.$white;
    }
}

.platformBar {
    z-index: 8;
}

.stickySelect {
    position: sticky;
    top: 60px;
    padding-top: 24px;
    margin-top: -24px;
    z-index: 6;
    background-color: g.$white;
}

.ymm {
    font-size: inherit;
    padding: 0;
    line-height: 24px;

    button,
    b {
        font-weight: inherit;
    }
}

.packageContainer {
    display: inline-block;
    white-space: nowrap;
}

.tabsWrapper:has(.channelItem) ~ .loadingSkeletonsWrapper {
    display: none;
}

.loadingSkeletons {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 4px;
}

.headerSkeleton {
    width: 100%;
    height: 34px;
    background-color: g.$off-white;
}

.downloadLinkDesktop {
    display: none;
    text-align: right;

    @include g.bp(md) {
        display: initial;
    }
}

.downloadLinkMobile {
    text-align: center;
    margin-top: 40px;

    @include g.bp(md) {
        display: none;
    }
}

.channelList {
    z-index: 1;

    .xlDescription {
        margin-top: 16px;

        span {
            @include g.set-font-weight('bold');
            margin-right: 4px;
        }
    }
}

.groupWrapper {
    min-height: 100vh;
}

.onlineOnly {
    text-align: center;
    @include g.set-font-weight('light');
    @include g.font-size(12px);
}

.showmore {
    width: 100%;
}

.showmoreButton {
    @include g.set-font-weight('normal');
    @include g.font-size(14px);
    white-space: nowrap;
    width: 100% !important;
    padding: 0 8px !important;
}

.showMoreText {
    flex-grow: 1;
}

.show {
    display: initial;
}

.hidden {
    &:not(.show) {
        display: none;
    }
}
