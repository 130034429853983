@use '@/styles/global' as g;

.slider {
    --slider-text-color: #{g.$black};

    //Pagination Dots
    ul[role='tablist'] {
        position: relative;
        margin-top: 24px;

        li>button {
            position: relative;
            background: transparent;
            width: 36px;
            height: 36px;
            opacity: 1;

            &::after {
                content: '';
                display: block;
                background: var(--blue);
                width: 10px;
                height: 10px;
                border-radius: 10px;
                margin: 0 auto;
                transition: transform 0.2s linear;
            }

            &[aria-selected='true'] {
                background: transparent;
                transform: none;

                &::after {
                    transform: scale(1.7);
                }
            }
        }
    }

    &.darkTheme {
        --slider-text-color: #{g.$white};

        &,
        * {
            color: var(--white);
        }

        ul[role='tablist'] li>button {
            background: transparent;

            &::after {
                background: var(--white);
            }
        }
    }

    &.arrowsTopAligned {
        div>button {
            @include g.bp(md) {
                top: 8vw;
            }

            @include g.bp(lg) {
                top: 50px;
            }
        }
    }

    &.Sports {
        margin-bottom: 32px;

        // When the pagaination is visible
        &:global(.is-overflow) {
            margin-bottom: 48px;
        }

        // Navigation Arrows
        div>button[type='button'] {
            background: var(--blue);
            border: none;
            height: 48px;
            width: 48px;
            opacity: 0;
            z-index: 2;
            transition: opacity 0.3s linear;

            &:hover,
            &:focus-visible {
                opacity: 1;
            }

            &[disabled] {
                display: none;
            }

            svg {
                fill: var(--white);
                height: 18px;
                width: 18px;
                z-index: 2;
            }

            &:first-of-type {
                left: 0;
            }

            &:last-of-type {
                right: 0;
            }

            &::before {
                content: '';
                display: block;
                height: 48px;
                width: 48px;
                background: var(--blue);
                border-radius: 50px;
                position: absolute;
            }

            &::after {
                content: '';
                display: block;
                width: 55px;
                height: 340px;
                position: absolute;
                z-index: -1;
            }

            &:global(.splide__arrow--prev)::after {
                background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
            }

            &:global(.splide__arrow--next)::after {
                background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
            }
        }

        //Pagination Dots
        ul[role='tablist'] {
            margin-top: 0;
            position: absolute;
            margin-top: 0;
            bottom: -26px;
        }

        .sliderTrack {
            padding-bottom: 24px;
        }
    }

    &.ContentShowcase {

        // Navigation Arrows
        div>button[type='button'] {
            display: none;
            background: rgba(0, 0, 0, 0.5);
            border: 2px solid var(--white);
            height: 28px;
            width: 28px;

            svg {
                fill: var(--white);
                height: 16px;
                width: 16px;
            }

            &:first-of-type {
                left: 0;
            }

            &:last-of-type {
                right: 0;
            }
        }

        // Slides
        ul[role='presentation'] {
            li {
                a {
                    text-decoration: none;
                    color: var(--black);

                    img {
                        transition: filter 0.1s linear;
                    }

                    &:hover {
                        // Medium-bold font weight imitation to prevent layout shift
                        text-shadow:
                            0 0 0.75px var(--slider-text-color),
                            0 0 0.75px var(--slider-text-color);

                        img {
                            filter: brightness(0.6);
                        }
                    }
                }

                .slideText {
                    @include g.font-size(12px);
                    margin-top: 8px;
                }
            }
        }

        &.showArrows {
            div>button {
                display: flex;
            }

            @include g.bp(md) {
                .sliderTrack {
                    width: 86%;
                    margin: 0 auto;
                }
            }
        }
    }

    &.EntityTileGrid, &.EntityList {
        ul {
            max-height: 315px;
    
            @include g.bp(md) {
                max-height: none; 
            }
            li {
                max-width: 315px;
    
                @include g.bp(md) {
                    max-width: none; 
                }
            }
        }
    }
}

.imageStyles {
    @include g.web2PageOverrides {
        border-radius: 24px;
    }
}
.linkWrapper {
    @include g.web2PageOverrides {
        height: 100%;
    }
}