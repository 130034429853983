@use '@/styles/global' as g;

.footerContainer {
    background-color: var(--inverse-background);
    padding: 56px 0;

    @include g.bp(md) {
        padding: 64px 0 24px;
    }

    @include g.bp(lg) {
        padding: 56px 0 24px;
    }

    .footerGrid {
        max-width: none;
    }
}