@use '@/styles/global' as g;

.searchContainer {
    width: 100%;
    text-align: left;
    padding-bottom: 48px;

    .suggestionsWrapper {
        margin-bottom: 48px;
    
        @include g.bp(md) {
            margin-bottom: 64px;

            @include g.bp(lg) {
                margin-bottom: 80px;
            }
        }
    }

    .linksWrapper {
        .searchQuickLinksHeading {
            text-align: center;
            margin-bottom: 24px;
    
            @include g.bp(md) {
                margin-bottom: 32px;
    
                @include g.bp(lg) {
                    margin-bottom: 40px;
                }
            }
        }
    }    
}
