@use '@/styles/global' as g;

.scheduleLineupHeader {
    text-align: right;
    color: g.$gray-dark;
    padding: 0;
    margin-bottom: 32px;

    .timezoneLabel {
        margin-left: 6px;
    }
}

.scheduleLineup {
    margin-bottom: 32px;

    .scheduleTime {
        @include g.font-size(14px);
        line-height: 22px;
        @include g.set-font-weight('light');
    }

    .scheduleNowPlaying {
        display: none;
        align-items: flex-start;
        padding-bottom: 2px;

        .nowPlayingSpan {
            margin-right: 10px;
        }

        p {
            line-height: 18px;
            @include g.font-size(12px);
            @include g.set-font-weight('light');
        }

        &.active {
            display: inline-flex;
        }
    }

    .scheduleItemTitle {
        display: block;
        @include g.font-size(14px);
        width: 100%;
        line-height: 22px;
        @include g.set-font-weight('bold');
        padding-bottom: 4px;
    }

    .scheduleItemDesc {
        line-height: 18px;
        margin-bottom: 4px;
        @include g.font-size(12px);
        @include g.set-font-weight('light');
    }
}