@use 'src/styles/global/mixins' as m;

.bar {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    --yearWidth: 20%;
    --makeWidth: 40%;
    --modelWidth: 40%;
    --marginBetween: 8px;

    &.evenly-spaced {
        --yearWidth: 33%;
        --makeWidth: 33%;
        --modelWidth: 33%;
    }

    .year,
    .make,
    .model {
        flex-basis: 100%;
        margin-bottom: 16px;
    }

    @include m.bp(md) {
        flex-wrap: nowrap;

        .year {
            flex-basis: var(--yearWidth);
            margin-right: var(--marginBetween);
            margin-bottom: 0;
        }

        .make {
            flex-basis: var(--makeWidth);
            margin-right: var(--marginBetween);
            margin-bottom: 0;
        }

        .model {
            flex-basis: var(--modelWidth);
            margin-bottom: 0;
        }
    }
}