@use '@/styles/global' as g;

@keyframes slideUp {
    from {
        display: block;
        bottom: -342px;
    }

    to {
        bottom: 0;
    }
}

@keyframes slideDown {
    from {
        display: block;
    }

    to {
        bottom: -342px;
    }
}

.trialEligibilityStickyBox {
    .stickyBox {
        z-index: 11;
        display: none;
        animation: slideDown forwards 0.4s ease-in;

        &.show {
            animation: slideUp forwards 0.4s ease-in;
            display: block;
        }

        .container {
            position: relative;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            background: g.$white;
            width: 100%;
            border-top: 1px solid g.$secondary-light-gray;
            box-shadow: g.$active-shadow;
        }

        .detailsCol {
            position: relative;

            .closeButton {
                position: absolute;
                top: 24px;
                right: 0;

                @include g.bp(lg) {
                    top: 50%;
                    transform: translateY(-50%);
                }

                svg {
                    stroke: g.$black;
                    height: 16px;
                    width: 16px;

                    @include g.bp(lg) {
                        height: 20px;
                        width: 20px;
                    }
                }
            }
        }

        .wrapper {
            max-width: 902px;
            padding: 48px 0 24px 0;
            margin: 0 auto;

            @include g.bp(lg) {
                padding: 32px 0;
            }
        }
    }
}