@use '@/styles/global' as g;

.offerDetails {
    padding: 24px 0;
    background-color: g.$off-white;

    @include g.bp(md) {
        padding: 32px 0;
    }
}
