@use '@/styles/global' as g;

.container {
    h2 {
        text-transform: capitalize;
        @include g.set-font-weight('black');
        margin-bottom: 48px;
    }

    p:last-of-type {
        color: g.$gray-dark;
        @include g.font-size(12px);
        margin-bottom: 48px;
        @include g.set-font-weight('light');

        a {
            margin-left: 5px;
        }
    }
}