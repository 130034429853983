@use '@/styles/global' as g;

.container {
    @include g.font-size(12px);
    padding: 10px 0;
    position: relative;
    z-index: 1;
}

.button {
    @include g.font-size(14px);
    color: g.$blue-secondary;
    @include g.set-font-weight('bold');
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px;
    border: 0;
    @include g.web2PageOverrides {
        color: var(--base-contrast-accent, #0000eb);
    }
}
.iconStyle {
    margin-left: 8px;
    width: 12px;
    height: 8px;
    stroke: var(--base-contrast);
    stroke-width: 2px;
}
