@use '@/styles/global' as g;

.wrapper {
    display: flex;
    flex-direction: column;
    gap: map-get(g.$page-layout-spacing, 'grande');
}

.buttonContainer {
    display: flex;
    justify-content: center;
}